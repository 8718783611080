import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "src/pages/NotFound";
import Home from "src/pages/Home";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <NotFound />,
        children: [
            { index: true, element: <Home /> },
            // { path: "about", element: <About /> },
            // { path: "projects", element: <Projects /> },
            // { path: "papers", element: <Papers /> },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);

reportWebVitals();
