import { Outlet } from "react-router-dom";
import Footer from "src/pages/Footer";
import Header from "src/components/Header";
import { Provider } from "react-redux";
import { store } from "src/app/store";

export const HOME_PATH = "/";

function App() {
    return (
        <>
            <Provider store={store}>
                <Header />
                <Outlet />
                <Footer />
            </Provider>
        </>
    );
}

export default App;
