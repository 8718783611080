import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { BgDescOpts } from "src/app/types";
import styles from "src/styles/TaskHelperPage.module.css";
import { WEDDING_CLOUDFRONT_SERVER } from "src/libs/constants";
import { useTaskParam } from "./paramsStore";
interface Iparams {
    show: boolean;
    onHide: () => void;
    bgDescOpts: BgDescOpts;
}

export default function TaskHelperPage(props: Iparams) {
    const { bgDesc, setBgType, setBgDesc } = useTaskParam();
    const exampleImage = ["user-uploads/examples/example1.png", "user-uploads/examples/example2.png"];
    const bgKeys = Object.keys(props.bgDescOpts);
    const [bgKey, setBgKey] = useState<string>(bgKeys[0]);
    const handleOnClickNav = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setBgKey(event.currentTarget.value);
    };

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Task Helper</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.bodySection}>
                <ul className={styles.navGroup}>
                    {bgKeys.map((key, index) => (
                        <button
                            className={`${styles.navGroupItem} ${styles.navGroupItemAction} ${
                                bgKey === key ? styles.active : ""
                            }`}
                            key={key}
                            value={key}
                            onClick={handleOnClickNav}
                        >
                            <li key={key}>{key}</li>
                        </button>
                    ))}
                </ul>
                <div className={styles.imgGroup}>
                    {props.bgDescOpts[bgKey].map((item) => (
                        <div>
                            <p>{item}</p>

                            <img
                                className={`${styles.imgGroupItem} ${item === bgDesc ? styles.active : ""}`}
                                src={`${WEDDING_CLOUDFRONT_SERVER}/user-uploads/examples/${bgKey}_${item}.png`}
                                alt={`${bgKey}_${item}.png`}
                                onClick={() => {
                                    setBgType(bgKey);
                                    setBgDesc(item);
                                    console.log(bgKey, item);
                                }}
                            />
                        </div>
                    ))}
                </div>

                <footer className={styles.footer}>
                    <a href="https://unsplash.com/ko/%EC%82%AC%EC%A7%84/haRyBAihS_0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        Unsplash
                    </a>
                    의
                    <a href="https://unsplash.com/ko/@camilacordeiro?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        Camila Cordeiro
                    </a>
                </footer>
            </Modal.Body>
            <Modal.Footer>
                <button className={styles.footerBtn} onClick={props.onHide}>
                    Select
                </button>
            </Modal.Footer>
        </Modal>
    );
}
