import * as t from "./types";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiServerUrl } from "src/config";

export const weddingPhotoApi = createApi({
    reducerPath: "wedding-photo-api",
    baseQuery: fetchBaseQuery({
        baseUrl: `${apiServerUrl}/wedding-photo/`,

        // prepareHeaders: (headers) => {
        //     headers.set("Access-Control-Allow-Origin", "*");
        //     headers.set("Content-Type", "application/json");
        //     return headers;
        // },
    }),
    tagTypes: ["user-files"],
    endpoints: (builder) => ({
        getTaskDetail: builder.query<t.ChangeBackgrondItem, t.TaskDetailReqParams>({
            query: ({ taskId }) => `task-detail/${taskId}`,
            // providesTags: (result, error, arg) => [
            //   { type: "task-detail", id: arg.taskId },
            // ],
        }),
        uploadFile: builder.mutation<t.UploadFileResponse, t.UploadFileReqParams>({
            query: ({ filename, file, type = "image" }) => {
                const formData = new FormData();
                formData.append("file", file, filename);
                formData.append("type", type);

                return {
                    method: "POST",
                    url: "file-upload",
                    body: formData,
                };
            },
            invalidatesTags: ["user-files"],
        }),
        taskSubmit: builder.mutation<t.ChangeBackgrondItem, t.ChangeBackgrondReqParams>({
            query: ({ s3_path, prompt, bgType, bgDesc }) => {
                return {
                    method: "POST",
                    url: "task-submit",
                    body: {
                        s3_path: s3_path,
                        prompt,
                        bgType,
                        bgDesc,
                    },
                };
            },
        }),
    }),
});

export const { useGetTaskDetailQuery, useUploadFileMutation, useTaskSubmitMutation } = weddingPhotoApi;
