import { useTaskSubmitMutation, useUploadFileMutation } from "src/app/weddingPhotoApi";
import DragDropZone from "src/components/DragDropZone";
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";

import ParamsPage from "./home/ParamsPage";
import styles from "src/styles/Home.module.css";
import { useTaskParam } from "./home/paramsStore";
import LoadingSpinner from "./LoadingSpinner";

import debounce from "lodash.debounce";
import ProgressBar from "src/components/ProgressBar";

const TaskDetailPage = lazy(() => import("src/pages/TaskDetailPage"));

const Home = () => {
    const [uploadFile, { data: uploadFileResp }] = useUploadFileMutation();
    const [taskSubmit, { data: task, isLoading }] = useTaskSubmitMutation();
    const [taskList, setTaskList] = useState<string[]>([]);
    const { imageUrl, prompt, bgType, bgDesc, setImageUrl } = useTaskParam();

    useEffect(() => {
        if (!uploadFileResp) return;

        setImageUrl(uploadFileResp.s3_path);
    }, [uploadFileResp, setImageUrl]);
    useEffect(() => {
        if (task && !isLoading) {
            setTaskList((prev) => [...prev, task.task_id]);
        }
    }, [task, isLoading]);
    console.log(task);

    const debouncedUploadFile = useCallback(debounce(uploadFile, 300), []);

    const onFileChange = useCallback(
        (file: File) => {
            if (file) {
                debouncedUploadFile({ filename: file.name, file });
            } else {
                setImageUrl("");
            }
        },
        [debouncedUploadFile, setImageUrl],
    );

    const onButtonClick = () => {
        taskSubmit({
            s3_path: imageUrl,
            prompt: prompt,
            bgType: bgType,
            bgDesc: bgDesc,
        });
    };
    // Memoized TaskDetailPage elements
    const taskDetailElements = useMemo(
        () =>
            taskList.map((taskId) => (
                <Suspense key={taskId} fallback={<LoadingSpinner />}>
                    <TaskDetailPage taskId={taskId} />
                </Suspense>
            )),
        [taskList],
    );

    return (
        <section className={styles.home}>
            <h1 className={styles.title}>Change the background of Wedding Photo</h1>
            <div className={styles.container}>
                <div className={styles.container__section}>
                    <DragDropZone imageUrl={imageUrl} onFileChange={onFileChange} />
                    <ParamsPage />
                </div>

                <button
                    className={styles.run_button}
                    onClick={onButtonClick}
                    style={{ display: "block", marginTop: "10px" }}
                >
                    Run
                </button>
            </div>
            {taskDetailElements}

            {isLoading && (
                <div className="mt-5 w-96">
                    <ProgressBar isLoading={isLoading} />
                </div>
            )}
        </section>
    );
};

export default Home;
