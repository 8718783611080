import React from "react";
import styles from "src/styles/NotFound.module.css";

export default function NotFound() {
    return (
        <div className={styles.notFoundContainer}>
            <div className={styles.errorCode}>404</div>
            <div className={styles.errorMessage}>Page Not Found</div>
            <div className={styles.errorDescription}>
                The page you are looking for might have been removed, had its name changed, or is temporarily
                unavailable.
            </div>
            <a href="/" className={styles.goHomeButton}>
                Go Home
            </a>
        </div>
    );
}
