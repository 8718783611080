import React, { useCallback } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { WEDDING_CLOUDFRONT_SERVER } from "src/libs/constants";
import styles from "src/styles/Dropzone.module.css";

interface IParams {
    imageUrl: string;
    onFileChange: (file: File) => void;
}

function DragDropZone({ imageUrl, onFileChange }: IParams) {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            onFileChange(file); // Update the imageUrl state
        },
        [onFileChange],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: "image/*" as unknown as Accept,
        multiple: false,
    });

    return (
        <div {...getRootProps()} className={styles.container__section__dropzon}>
            <input {...getInputProps()} />
            {imageUrl ? (
                <img src={`${WEDDING_CLOUDFRONT_SERVER}/${imageUrl}`} alt="Preview" className={styles.image} />
            ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
            )}
        </div>
    );
}

export default DragDropZone;
