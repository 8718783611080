export const SERVICE_STAGE = process.env.REACT_APP_ENV ?? "dev";

export const appConfig =
    SERVICE_STAGE === "dev"
        ? {
              apiServerUrl: "https://rqkhqmccdwahq7c4qv7w3jyyga0tpych.lambda-url.ap-northeast-2.on.aws/",
          }
        : {
              apiServerUrl: "http://127.0.0.1:8000",
          };

export const { apiServerUrl } = appConfig;
