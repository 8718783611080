import { create, SetState } from "zustand";
// import {ChangeBackgrondReqParams} from "src/app/types"

type TaskParamState = {
    imageUrl: string;
    prompt: string;
    bgType: string;
    bgDesc: string;
    setImageUrl: (imageUrl: string) => void;
    setPrompt: (prompt: string) => void;
    setBgType: (bgType: string) => void;
    setBgDesc: (bgDesc: string) => void;
};

// Zustand 상태를 생성합니다.
export const useTaskParam = create<TaskParamState>((set: SetState<TaskParamState>) => ({
    imageUrl: "",
    prompt: " ",
    bgType: "indoor",
    bgDesc: "wedding studio",
    setImageUrl: (imageUrl: string) => {
        set({ imageUrl });
    },
    setPrompt: (prompt: string) => {
        set({ prompt });
    },
    setBgType: (bgType: string) => {
        set({ bgType });
    },
    setBgDesc: (bgDesc: string) => {
        set({ bgDesc });
    },
}));
