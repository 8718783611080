import React from "react";
import styles from "src/styles/Header.module.css";

export default function Header() {
    const navMenus = [
        {
            name: "Menu",
            path: "/asdf",
        },
        {
            name: "Tools&API",
            path: "/asdf",
        },
        {
            name: "Pricing",
            path: "/asdf",
        },
    ];
    return (
        <nav className={styles.navigation}>
            <div className={styles.navigation__after}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className={styles.navigation__logo}>
                        <img src="/images/logo.png" alt="logo" className={styles.image} />
                    </div>
                    <ul className={styles.navigation__menu}>
                        {navMenus.map((navMen, index) => (
                            <li key={index} className={styles.menu__name}>
                                {navMen.name}
                            </li>
                        ))}
                    </ul>
                    <ul className={styles.navigation__sign}>
                        <li className={styles.menu__name}>Login</li>
                        <li className={styles.menu__name}>SignUp</li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
