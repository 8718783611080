import { weddingPhotoApi } from "src/app/weddingPhotoApi";
// import authReducer from "./slices/authSlice";

import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
    reducer: {
        [weddingPhotoApi.reducerPath]: weddingPhotoApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(weddingPhotoApi.middleware),
});

// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
