// ProgressBar.tsx
import React, { useEffect, useState } from "react";
import styles from "src/styles/ProgressBar.module.css";

interface ProgressBarProps {
    isLoading: boolean;
}

export default function ProgressBar({ isLoading }: ProgressBarProps) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const totalTime = 60000; // 1 minute in milliseconds
        const intervalTime = 100; // 100 milliseconds
        const increment = 100 / (totalTime / intervalTime);

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + increment;

                if (nextProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                if (!isLoading) {
                    clearInterval(interval);
                    return 100;
                }

                return nextProgress;
            });
        }, intervalTime);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <div className={styles.progressContainer}>
                <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
            </div>
            <div className={styles.progressText}>{Math.round(progress)}%</div>
        </>
    );
}
