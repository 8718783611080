import React, { ChangeEvent, useCallback, useState } from "react";
import { useTaskParam } from "./paramsStore"; // 가정: useTaskParam 타입이 이미 적절히 지정되어 있음.
import styles from "src/styles/ParamPage.module.css";
import { WEDDING_CLOUDFRONT_SERVER } from "src/libs/constants";
import { SiHelpdesk } from "react-icons/si";
import TaskHelperPage from "src/pages/home/TaskHelperPage";
import { BgDescOpts } from "src/app/types";

export default function ParamsPage() {
    const { prompt, bgType, setImageUrl, setPrompt, setBgType, setBgDesc } = useTaskParam();

    const exampleImage = ["user-uploads/examples/example1.png", "user-uploads/examples/example2.png"];

    const bgTypeOpts = ["indoor", "outdoor", "utilizing_background", "vehicle", "others"];

    const bgDescOpts: BgDescOpts = {
        indoor: ["wedding studio", "hotel", "exotic space"],
        outdoor: ["sea", "mountain", "in a park"],
        utilizing_background: ["hospitals", "schools", "airports", "movie theaters"],
        vehicle: ["limousine", "mercedes-benz", "rolls-royce", "peugeot", "airplane"],
        others: ["medieval", "classic", "modern"],
    };

    const [taskHelperShow, setTaskHelperShow] = useState(false);
    const handleTaskHelperShow = useCallback(() => {
        setTaskHelperShow(false);
    }, []);

    const handleBgTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setBgType(value);
        setBgDesc(bgDescOpts[value][0]);
    };

    const handleBgDescChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setBgDesc(e.target.value);
    };

    const handlePromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setPrompt(e.target.value);
    };

    return (
        <section className={styles.container}>
            <p className={styles.container__select_title}>background type</p>
            <div className={styles.select__container}>
                <select
                    className={styles.container__select}
                    aria-label="Default select example"
                    onChange={handleBgTypeChange}
                >
                    {bgTypeOpts.map((type, index) => (
                        <option key={index} value={type}>
                            {type}
                        </option>
                    ))}
                </select>

                <select
                    className={styles.container__select}
                    aria-label="Default select example"
                    onChange={handleBgDescChange}
                >
                    {bgDescOpts[bgType].map((item, index) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                <button onClick={() => setTaskHelperShow(true)}>
                    <SiHelpdesk className={styles.taskHelperBtn} />
                </button>
                <TaskHelperPage show={taskHelperShow} onHide={handleTaskHelperShow} bgDescOpts={bgDescOpts} />
            </div>
            <p className={styles.container__select_title}>Add additional information</p>
            <textarea
                className={styles.container__input_text}
                placeholder="Prompt"
                id="inputPrompt"
                aria-describedby="Prompt"
                maxLength={50}
                value={prompt}
                onChange={handlePromptChange}
            />
            <p className={styles.counter_style}>{prompt.length}/50</p>
            <div className={styles.example_container}>
                {exampleImage.map((path, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            setImageUrl(path);
                        }}
                    >
                        <img
                            className={styles.example_container__image}
                            key={index}
                            src={`${WEDDING_CLOUDFRONT_SERVER}/${path}`}
                            alt=""
                        />
                    </button>
                ))}
            </div>
        </section>
    );
}
